import { createSlice } from '@reduxjs/toolkit'

const configInitialState = {
  yandexCaptcha: {
    enabled: false,
    clientKey: undefined
  }
}

export const slice = createSlice({
  name: 'config',
  initialState: configInitialState,
  reducers: {
    setConfig: (state, { payload }) => ({ ...state, ...payload })
  }
})

const { setConfig } = slice.actions

export const saveConfig = (config) => (dispatch) => {
  dispatch(setConfig(config))
}

export default slice.reducer
